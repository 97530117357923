<template>
	<div class="BusinessDetails">
		<w-navTab titleText="商圈详情"></w-navTab>
		<div class="container">
			<div class="head-nav">
				<div class="head-title">{{ businessDetail.title }}</div>
				<div class="head-bottom">
					<div class="bottom-left">
						<div class="head-bottom-item">
							<span>时间: {{ businessDetail.create_time }}</span>
						</div>
					</div>
					<div class="bottom-right">
						<div class="head-bottom-item">
							<span>点赞: {{ businessDetail.approval }}</span>
							<span>阅读: {{ businessDetail.views }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="content" v-html="businessDetail.content"></div>
			<div class="content-picture">
				<div v-for="(item, index) in businessDetail.picture"><img :src="item"
						@click="showPopup(businessDetail.picture,index)" /></div>
			</div>
			<div class="middle">
				<div class="middle-top">
					<div class="middle-top-picture"><img :src="businessDetail.avatar" /></div>
					<div class="middle-top-title">{{ businessDetail.nickname }}</div>
				</div>
				<div class="middle-bottom">
					<div>名称：{{ businessDetail.name }}</div>
					<div>电话：{{ businessDetail.telephone }}</div>
					<div>地址：{{ businessDetail.address }}</div>
					<div class="footer-btn">
						<div class="footer-btn-text" @click="toNav">一键导航</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<!-- 点赞 -->
				<div class="footer-item" @click="onPraise" v-show="!isPraise">
					<div class="picture"><img src="@/assets/img/wz1.png" /></div>
				</div>
				<div class="footer-item footer-item-like" @click="unPraise" v-show="isPraise">
					<div class="picture"><img src="@/assets/img/wz11.png" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import News from '@/api/news';
	import Vant from '@/vendor/vant';
	import Business from '@/api/business';
	export default {
		name: 'BusinessDetails',
		data() {
			return {
				businessDetail: '',
				isPraise: '',
				isShowShare: false,
				pageId: ''
			};
		},
		created() {
			this.pageId = this.$route.params.id;

			let params = {
				post_id: this.pageId,
				type_id: 2
			};

			Business.detail(params)
				.then(result => {
					this.businessDetail = result.data;
				})
				.catch(error => {});

			News.isPraise({
					page_id: this.pageId,
					type_id: 2
				})
				.then(result => {
					this.isPraise = result.data.is_praise;
				})
				.catch(error => {});
		},
		methods: {
			showPopup(item, index) {
				Vant.ImagePreview({
					images: item,
					startPosition: index
				});
			},
			// 点赞
			onPraise() {
				let params = {
					page_id: this.pageId,
					type_id: 2
				};
				News.praise(params)
					.then(result => {
						Vant.Toast('点赞成功');
						this.businessDetail.approval++;
						this.isPraise = true;
					})
					.catch(error => {
						Vant.Toast('点赞失败');
					});
			},
			// 取消点赞
			unPraise() {
				let params = {
					page_id: this.pageId,
					type_id: 2
				};
				News.unPraise(params)
					.then(result => {
						Vant.Toast('取消点赞成功');
						this.businessDetail.approval--;
						this.isPraise = false;
					})
					.catch(error => {
						Vant.Toast('取消点赞失败');
					});
			},
			// 导航
			toNav() {
				let url = [
					`https://mapapi.qq.com/web/mapComponents/locationMarker/v/index.html?`,
					`marker=coord:${this.businessDetail.latitude},${this.businessDetail.longitude};title:${this.businessDetail.name};addr:${this.businessDetail.address}`
				];

				window.open(url.join(''));
			}
		}
	};
</script>

<style scoped lang="less">
	.public {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.publ {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		color: #aaa;
		padding: 18px;
		box-sizing: border-box;
	}

	.BusinessDetails {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;

		.container {
			padding: 20px;
			box-sizing: border-box;

			.head-nav {
				border-bottom: 1px solid #ddd;
				padding-bottom: 2px;

				.head-title {
					font-size: 18px;
					line-height: 26px;
					color: #222;
					margin-bottom: 5px;
					font-weight: bold;
				}

				.head-bottom {
					font-size: 12px;
					line-height: 26px;
					color: #777;
					display: flex;
					justify-content: space-between;
					align-items: center;

					span {
						flex: 1;
					}

					.bottom-left {
						.public();
						display: flex;

						span {
							margin-right: 10px;

							&:last-child {
								margin-right: 0;
							}
						}
					}

					.bottom-right {
						.public();
						display: flex;

						span {
							margin-left: 10px;

							&:first-child {
								margin-left: 0;
							}
						}
					}
				}
			}

			.content {
				padding: 20px 0 0 0;
				font-size: 16px;
				line-height: 26px;
				color: #222;
				word-break: break-all;
				white-space: normal;
			}

			.content-picture {
				display: flex;
				justify-content: space-between;
				padding-bottom: 15px;
				flex-wrap: wrap;

				div {
					width: 160px;
					height: 100px;
					margin-top: 15px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.middle {
				padding: 15px 0;
				border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;

				.middle-top {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 10px;

					.middle-top-picture {
						min-width: 32px;
						width: 32px;
						height: 32px;
						border-radius: 50%;
						margin-right: 5px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 50%;
						}
					}

					.middle-top-title {
						font-size: 18px;
						line-height: 26px;
						color: #222;
					}
				}

				.middle-bottom {
					font-size: 18px;
					line-height: 28px;
					color: #222;
				}
			}

			.footer {
				padding: 20px 90px 20px;
				display: flex;
				justify-content: center;

				.footer-item {
					background-color: #ddd;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					color: #aaa;
					padding: 18px;
					box-sizing: border-box;

					.picture {
						width: 24px;
						height: 24px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.footer-item-like {
					background: #ff6288;
					.publ();
				}

				.footer-item-collect {
					background: #ffb609;
					.publ();
				}

				.footer-item-share {
					background: #4399ff;
					.publ();
				}
			}

			.footer-btn {
				margin-top: 15px;

				.footer-btn-text {
					width: 100%;
					background: #3377FF;
					border-radius: 100px;
					font-size: 18px;
					line-height: 26px;
					text-align: center;
					color: #fff;
					padding: 8px 0;
				}
			}
		}
	}
</style>
